@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a {
    @apply cursor-pointer;
  }
}

.ellipse-bg{
  background-image: url("../public/ellipse.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #e8e8e8;
}


::-webkit-scrollbar-thumb {
 background-color: #AE94C2;
}

@font-face {
  font-family: 'Canela';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../public/fonts/canela/Canela-Thin.otf") format('opentype');
}

@font-face {
  font-family: 'Canela';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../public/fonts/canela/Canela-Light.otf") format('opentype');
}

@font-face {
  font-family: 'Canela';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../public/fonts/canela/Canela-Regular.otf") format('opentype');
}

@font-face {
  font-family: 'Canela';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../public/fonts/canela/Canela-Medium.otf") format('opentype');
}

@font-face {
  font-family: 'Canela';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../public/fonts/canela/Canela-Bold.otf") format('opentype');
}

@font-face {
  font-family: 'Canela';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../public/fonts/canela/Canela-Black.otf") format('opentype');
}

@font-face {
  font-family: 'Canela';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("../public/fonts/canela/Canela-ThinItalic.otf") format('opentype');
}

@font-face {
  font-family: 'Canela';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("../public/fonts/canela/Canela-LightItalic.otf") format('opentype');
}

@font-face {
  font-family: 'Canela';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../public/fonts/canela/Canela-Italic.otf") format('opentype');
}

@font-face {
  font-family: 'Canela';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("../public/fonts/canela/Canela-MediumItalic.otf") format('opentype');
}

@font-face {
  font-family: 'Canela';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../public/fonts/canela/Canela-BoldItalic.otf") format('opentype');
}

@font-face {
  font-family: 'Canela';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("../public/../public/fonts/canela/Canela-BlackItalic.otf") format('opentype');
}

/* brown font */

@font-face {
  font-family: 'Brown';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../public/fonts/brown/Brown-Thin.otf") format('opentype');
}

@font-face {
  font-family: 'Brown';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../public/fonts/brown/Brown-Light.otf") format('opentype');
}

@font-face {
  font-family: 'Brown';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../public/fonts/brown/Brown-Regular.otf") format('opentype');
}

@font-face {
  font-family: 'Brown';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../public/fonts/brown/Brown-Regular-Alt.otf") format('opentype');
}

@font-face {
  font-family: 'Brown';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../public/fonts/brown/Brown-Bold.otf") format('opentype');
}


@font-face {
  font-family: 'Brown';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../public/fonts/brown/Brown-Bold-Alt.otf") format('opentype');
}

@font-face {
  font-family: 'Brown';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("../public/fonts/brown/Brown-Thin-Italic.otf") format('opentype');
}

@font-face {
  font-family: 'Brown';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("../public/fonts/brown/Brown-Light-Italic.otf") format('opentype');
}

@font-face {
  font-family: 'Brown';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../public/fonts/brown/Brown-Regular-Italic.otf") format('opentype');
}

@font-face {
  font-family: 'Brown';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("../public/fonts/brown/Brown-Regular-Italic-Alt.otf") format('opentype');
}

@font-face {
  font-family: 'Brown';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../public/fonts/brown/Brown-Bold-Italic.otf") format('opentype');
}

@font-face {
  font-family: 'Brown';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("../public/../public/fonts/brown/Brown-Bold-Italic-Alt.otf") format('opentype');
}